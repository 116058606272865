// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// const firebaseConfig = {
//   apiKey: "AIzaSyCQgFKNbxOylrkmW5_fORtUahsfClOIVAs",
//   authDomain: "miss-wellness.firebaseapp.com",
//   databaseURL: "https://miss-wellness-default-rtdb.firebaseio.com",
//   projectId: "miss-wellness",
//   storageBucket: "miss-wellness.appspot.com",
//   messagingSenderId: "230067708125",
//   appId: "1:230067708125:web:6634ad68e489945ace7b34",
//   measurementId: "G-WHCC3DFH0B"
// };


const firebaseConfig = {
  apiKey: "AIzaSyBu0FlfO3ZW-pdjueRKsJUfLDtJ8uP4GyU",
  authDomain: "miss-wellness-d516d.firebaseapp.com",
  projectId: "miss-wellness-d516d",
  storageBucket: "miss-wellness-d516d.firebasestorage.app",
  messagingSenderId: "453967268248",
  appId: "1:453967268248:web:2ca645f3f491faaa54c3ed",
  measurementId: "G-9HPW9XSBMJ"
};




// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const auth = getAuth(app);
export { signInWithEmailAndPassword };