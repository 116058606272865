import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation } from "react-router-dom";
import logo from "./../image/logo512.png";
import api from "./../api";

const DetailScore = () => {
  const location = useLocation();
  const [selectedStation, setSelectedStation] = useState("Station1");
  const [evaluations, setEvaluations] = useState({});
  const [Director, setDirector] = useState([]);
  const ContsId = location.state?.ConstID;

  const handleStationClick = (station) => {
    setSelectedStation(station);
  };

  const getDirector = async () => {
    try {
      const response = await api.post("/getAllDirectors");
      setDirector(response.data.data);
    } catch (error) {
      console.error(
        "get All Data error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const getEvaluation = async () => {
    try {
      const response = await api.post(`/getEvaluationConts/${ContsId}`);
      setEvaluations(response.data.data);
    } catch (error) {
      console.error(
        "Error fetching evaluation:",
        error.response ? error.response.data : error.message
      );
    }
  };

  useEffect(() => {
    getDirector();
    getEvaluation();
  }, []);

  return (
    <div className="container mt-3">
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img
              src={logo}
              alt="Logo"
              style={{ width: "30px", marginRight: "10px" }}
            />
            Detail Score - Contestant ID: {ContsId}
          </a>
        </div>
      </nav>

      <div className="d-flex justify-content-center mt-4">
        <div className="btn-group" role="group" aria-label="Station Buttons">
          {["Station1", "Station2"].map((station) => (
            <button
              key={station}
              type="button"
              className={`btn ${
                selectedStation === station
                  ? "btn-primary text-white"
                  : "btn-outline-primary"
              }`}
              onClick={() => handleStationClick(station)}
            >
              {station.replace("Station", "Station ")}
            </button>
          ))}
        </div>
      </div>

      <div className="mt-5">
        {selectedStation ? (
          <>
            <h3 className="text-center mb-4 text-secondary">
              Details for {selectedStation.replace("Station", "Station ")}
            </h3>
            <table className="table table-bordered table-hover shadow-sm rounded">
              <thead className="table-dark">
                <tr>
                  <th className="text-center">ID</th>
                  <th className="text-center">Q1</th>
                  <th className="text-center">Q2</th>
                  <th className="text-center">Q3</th>
                  <th className="text-center">Q4</th>
                  <th className="text-center">Q5</th>
                  {/* เงื่อนไขการแสดง Q4 */}
                  {selectedStation !== "Station2" &&
                    ((<th className="text-center">Q6</th>),
                    (<th className="text-center">Q7</th>))}
                  {/* เงื่อนไขการแสดง Q5 */}

                  <th className="text-center">Total Score</th>
                </tr>
              </thead>
              <tbody>
                {Director.filter((data) => data.ID !== "A001").map((data) => {
                  let stationScore = {};
                  if (Array.isArray(evaluations)) {
                    evaluations.forEach((evaluation) => {
                      if (evaluation[selectedStation]?.[data.ID]) {
                        stationScore =
                          evaluation[selectedStation][data.ID].score || {};
                      }
                    });
                  } else if (
                    typeof evaluations === "object" &&
                    evaluations !== null
                  ) {
                    if (evaluations[selectedStation]?.[data.ID]) {
                      stationScore =
                        evaluations[selectedStation][data.ID].score || {};
                    }
                  }

                  const totalScore = stationScore.result || "";

                  return (
                    <tr key={data.ID}>
                      <td className="text-center fw-bold">{data.ID}</td>
                      <td className="text-center">
                        {stationScore[`${selectedStation}-Q1`] || ""}
                      </td>
                      <td className="text-center">
                        {stationScore[`${selectedStation}-Q2`] || ""}
                      </td>
                      <td className="text-center">
                        {stationScore[`${selectedStation}-Q3`] || ""}
                      </td>
                      <td className="text-center">
                        {stationScore[`${selectedStation}-Q4`] || ""}
                      </td>
                      <td className="text-center">
                        {stationScore[`${selectedStation}-Q5`] || ""}
                      </td>
                      {selectedStation !== "Station2" && (
                        <td className="text-center">
                        {stationScore[`${selectedStation}-Q6`] || ""}
                      </td>,
                      <td className="text-center">
                      {stationScore[`${selectedStation}-Q7`] || ""}
                    </td>
                      )}

                      <td className="text-center text-success fw-bold">
                        {totalScore}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        ) : (
          <p className="text-center text-secondary">
            Please select a station to view the details.
          </p>
        )}
      </div>
    </div>
  );
};

export default DetailScore;
