import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import api from "./../api";

const Monitor = () => {
  const [director, setDirector] = useState([]);
  const [contestants, setContestants] = useState([]);
  const [selectedContestant, setSelectedContestant] = useState("");
  const [selectedStation, setSelectedStation] = useState("");
  const [dataMonitor, setAlldataMonitor] = useState({});

  const getDirector = async () => {
    try {
      const response = await api.post("/getAllDirectors");
      setDirector(response.data.data);
    } catch (error) {
      console.error(
        "get All Data error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const stationMapping = {
    "หมวด 12 อ คิดสร้างสรรค์และ การตอบคำถามและทัศนคติ (ส่วนบุคคล)": "Station1",
    "หมวดเดินแบบชุดราตรี" : "Station2",
  };

  const getContestants = async () => {
    try {
      const response = await api.post("/getAllDataConts");
      setContestants(response.data.data);
    } catch (error) {
      console.error("Error fetching contestants:", error);
    }
  };

  const getAllmonitor = async () => {
    try {
      const response = await api.post("/getAllmonitor");
      setAlldataMonitor(response.data.data);
    } catch (error) {
      console.error("Error fetching monitor data:", error);
    }
  };

  const setMonitor = async (dirID, userID) => {
    try {
      await api.post(`/setmonitor`, {
        userID: userID,
        DirID: dirID,
      });
      console.log(`Monitor set for DirID: ${dirID} with userID: ${userID}`);
    } catch (error) {
      console.error("Error setting monitor data:", error);
    }
  };

  useEffect(() => {
    getDirector();
    getContestants();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getAllmonitor();
    },300);
    return () => clearInterval(intervalId);
  }, []);

  const handleContestantChange = async (e) => {
    const selectedID = e.target.value;
    setSelectedContestant(selectedID);
    if (selectedID) {
      for (const dir of director.filter((dir) => dir.ID !== "A001")) {
        await setMonitor(dir.ID, selectedID);
      }
    }
  };

  const handleInstructionClick = async () => {
    // When button is clicked, send "Instruction" instead of selectedContestant
    for (const dir of director.filter((dir) => dir.ID !== "A001")) {
      await setMonitor(dir.ID, "Instruction");
    }
  };

  const handlesetStation = async (event) => {
    const selectedCategory = event.target.value;
    const station = stationMapping[selectedCategory];
    await setMonitor("Station", station);
    setSelectedStation(selectedCategory);
  };

  const getCellStyle = (dirID) => {
    if (selectedContestant === "") {
      return {};
    }
    if (dataMonitor[dirID] === selectedContestant) {
      return { backgroundColor: "#28a745", color: "white" };
    } else {
      return { backgroundColor: "#dc3545", color: "white" };
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center text-primary mb-4">
        Monitoring System Status
      </h2>

      <div className="row mb-4 align-items-center">
        {/* Dropdown เลือกหมวด */}
        <div className="col-md-4">
          <select
            id="categoryDropdown"
            className="form-select form-select-lg"
            value={selectedStation}
            onChange={handlesetStation}
          >
            <option value="">-- เลือกหมวด --</option>
            {Object.keys(stationMapping).map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>

        {/* Dropdown เลือก Contestant */}
        <div className="col-md-4">
          <select
            id="contestantDropdown"
            className="form-select form-select-lg"
            value={selectedContestant}
            onChange={handleContestantChange}
          >
            <option value="">-- Select Contestant --</option>
            {contestants.map((contestant) => (
              <option key={contestant.ID} value={contestant.ID}>
                {contestant.ID}
              </option>
            ))}
          </select>
        </div>

        {/* ปุ่ม Set Instruction */}
        <div className="col-md-4">
          <button
            className="btn btn-warning btn-lg w-100"
            onClick={handleInstructionClick}
          >
            Set Instruction
          </button>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-bordered shadow-sm rounded">
          <thead className="table-dark">
            <tr>
              <th className="text-center">Director ID</th>
              <th className="text-center">Contestant</th>
            </tr>
          </thead>
          <tbody>
            {director
              .filter((dir) => dir.ID !== "A001")
              .map((dir) => (
                <tr key={dir.ID}>
                  <td className="text-center fw-bold">{dir.ID}</td>
                  <td
                    style={getCellStyle(dir.ID)}
                    className="text-center fw-bold"
                  >
                    {dataMonitor[dir.ID] || ""}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Monitor;
