import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import api from "./../api";

const Leaderboard = () => {
  const [finalRankingData, setFinalRankingData] = useState([]);
  
  // ฟังก์ชันสำหรับดึงข้อมูลจาก API
  const getFinalRanking = async () => {
    try {
      const response = await api.post(`/getFinalranking`);
      if (response.status === 200 && response.data.data) {
        const rankingData = response.data.data;
        
        // แปลงข้อมูลจาก object เป็น array เพื่อให้สามารถ map ได้ง่าย
        const formattedData = Object.keys(rankingData).map((key) => ({
          rank: key,
          ID: rankingData[key].ID,
          Name: rankingData[key].Name,
          Result: rankingData[key].Result,
        }));

        setFinalRankingData(formattedData);
      }
    } catch (error) {
      console.error("Error fetching final ranking:", error.message);
      alert("An error occurred while fetching final ranking.");
    }
  };

  // เรียกใช้ getFinalRanking เมื่อ component ถูก render
  useEffect(() => {
    getFinalRanking();
  }, []);

  return (
    <div className="container mt-5">
      <h2 className="text-center text-primary mb-4">Leaderboard</h2>
      <table className="table table-hover table-striped shadow-sm rounded">
        <thead className="table-dark">
          <tr>
            <th className="text-center">ลำดับ (Rank)</th>
            <th className="text-center">ID</th>
            <th>Name</th>
            <th className="text-center">Result</th>
          </tr>
        </thead>
        <tbody>
          {finalRankingData.length > 0 ? (
            finalRankingData.map((contestant) => (
              <tr key={contestant.ID}>
                <td className="text-center">{contestant.rank}</td>
                <td className="text-center">{contestant.ID}</td>
                <td>{contestant.Name}</td>
                <td className="text-center fw-bold">{contestant.Result}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center">
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Leaderboard;
