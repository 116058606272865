import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import logo from "./image/logo512.png";
import Score from "./component-admin/Score";
import Monitor from "./component-admin/monitor";
import HomeADMIN from "./component-admin/homeAdmin";
import Ranking from "./component-admin/ranking";
import Leaderboard from "./component-admin/leaderboard";

const AdminHome = () => {
  const [activeTab, setActiveTab] = useState("monitor");

  const renderContent = () => {
    switch (activeTab) {
      case "monitor":
        return <Monitor />;
      case "home":
        return <HomeADMIN />;
      case "Score":
        return <Score />;
      case "StationRanking":
        return <Ranking />;
      case "Leaderboard":
        return <Leaderboard />;
      default:
        return <h2>Welcome to the Admin Panel</h2>;
    }
  };

  return (
    <div className="container mt-3">
      {/* Navbar */}
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img
              src={logo}
              alt="Logo"
              style={{ width: "30px", marginRight: "10px" }}
            />
            Admin
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <button
                  className={`nav-link ${activeTab === "home" ? "active" : ""}`}
                  onClick={() => setActiveTab("home")}
                >
                  Home
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "settings" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("Score")}
                >
                  Score
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "monitor" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("monitor")}
                >
                  Monitor
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "monitor" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("StationRanking")}
                >
                  StationRanking
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "monitor" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("Leaderboard")}
                >
                  Leaderboard
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Body Content */}
      <div className="mt-4">{renderContent()}</div>
    </div>
  );
};

export default AdminHome;
