import React, { useEffect, useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import api from "./../api";

const HomeADMIN = () => {
  const [selectedStation, setSelectedStation] = useState("Station1");
  const [director, setDirector] = useState([]);
  const [contestants, setContestants] = useState([]);
  const [stationData, setStationData] = useState({});
  const [columnRange, setColumnRange] = useState("1-15");
  // const fetchedDataRef = useRef(new Set());

  const handleStationClick = (station) => setSelectedStation(station);

  const handleColumnRangeChange = (range) => setColumnRange(range);

  const getDirector = async () => {
    try {
      const response = await api.post("/getAllDirectors");
      setDirector(response.data.data);
    } catch (error) {
      console.error("Error fetching directors:", error);
    }
  };

  const getContestants = async () => {
    try {
      const response = await api.post("/getAllDataConts");
      setContestants(response.data.data);
    } catch (error) {
      console.error("Error fetching contestants:", error);
    }
  };

  const getStation = async (Contsid, DirectorID) => {
    // if (fetchedDataRef.current.has(`${Contsid}-${DirectorID}`)) return;

    try {
      const response = await api.post(
        `/getDirDocument/${Contsid}/${DirectorID}`
      );
      setStationData((prevData) => ({
        ...prevData,
        [`${Contsid}-${DirectorID}`]: response.data,
      }));
      // fetchedDataRef.current.add(`${Contsid}-${DirectorID}`);

      console.log("get Data:");
    } catch (error) {
      console.error("Error fetching station data:", error);
    }
  };

  useEffect(() => {
    getDirector();
    getContestants();
  }, []);

  useEffect(() => {
    contestants.forEach((contestant) => {
      director.forEach((dir) => {
        if (dir.ID !== "A001") {
          getStation(contestant.ID, dir.ID);
        }
      });
    });
  }, [contestants, director]);

  const isSelectedStation = (data) =>
    data && data.documentNames.includes(selectedStation);

  const filteredDirectors = () => {
    const startIndex = columnRange === "1-15" ? 0 : 15;
    const endIndex = columnRange === "1-15" ? 15 : 30;
    return director.filter(
      (dir, index) =>
        index >= startIndex && index < endIndex && dir.ID !== "A001"
    );
  };

  return (
    <div className="container mt-4">
      <h2 className="text-center mb-4">Admin Dashboard</h2>

      {/* Station Button Group */}
      <div className="d-flex justify-content-center mb-4">
        <div className="btn-group">
          
          {["Station1", "Station2"].map(
            (station) => (
              <button
                key={station}
                type="button"
                className={`btn ${
                  selectedStation === station
                    ? "btn-primary"
                    : "btn-outline-primary"
                }`}
                onClick={() => handleStationClick(station)}
              >
                {station}
              </button>
            )
          )}
        </div>
      </div>


      <h5 className="text-center">Selected Station: {selectedStation}</h5>
      <table className="table table-bordered table-hover shadow mt-3">
        <thead className="table-dark">
          <tr>
            <th>Contestant ID</th>
            {filteredDirectors().map((dir) => (
              <th key={dir.ID} className="text-center">
                {dir.ID}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {contestants.map((contestant) => (
            <tr key={contestant.ID}>
              <td className="text-center">{contestant.ID}</td>
              {filteredDirectors().map((dir) => {
                const data = stationData[`${contestant.ID}-${dir.ID}`];
                return (
                  <td
                    key={`${contestant.ID}-${dir.ID}`}
                    className={`text-center `}
                  >
                    {data && isSelectedStation(data) ? "✔" : "-"}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default HomeADMIN;
