import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import api from "./../api";

const Score = () => {
  const [contestants, setContestants] = useState([]); // State to store contestant data
  const [evaluations, setEvaluations] = useState({}); // State to store evaluations by ID
  const navigate = useNavigate();

  const getConts = async () => {
    try {
      const response = await api.post("/getAllDataConts");
      setContestants(response.data.data); // Assuming response.data.data contains contestant list
    } catch (error) {
      console.error(
        "get All Data error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const getEvaluation = async (ConstID) => {
    try {
      const response = await api.post(`/getEvaluationConts/${ConstID}`);
      setEvaluations((prevEvaluations) => ({
        ...prevEvaluations,
        [ConstID]: response.data.data,
      }));
    } catch (error) {
      console.error(
        "Error fetching evaluation:",
        error.response ? error.response.data : error.message
      );
    }
  };

  useEffect(() => {
    getConts();
  }, []);

  useEffect(() => {
    contestants.forEach((contestant) => {
      getEvaluation(contestant.ID);
    });
  }, [contestants]);

  const calculateStationResult = (evaluationData, station) => {
    if (Array.isArray(evaluationData)) {
      const stationData = evaluationData.find((item) => item[station]);
      if (!stationData) {
        return 0;
      }
      const scores = Object.values(stationData[station]).map(
        (contestantData) => {
          const result = contestantData.score?.result || 0;
          return result;
        }
      );
      return scores.reduce((total, score) => total + score, 0);
    } else {
      return 0;
    }
  };

  const handleButtonClick = (ConstID) => {
    navigate("/detail", { state: { ConstID } });
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center text-primary mb-4">Detailed Scores</h2>
      <table className="table table-hover table-striped shadow-sm rounded">
        <thead className="table-dark">
          <tr>
            <th scope="col" className="text-center">
              ID
            </th>
            <th scope="col">Name</th>
            <th scope="col" className="text-center">
              Station 1
            </th>
            <th scope="col" className="text-center">
              Station 2
            </th>
            
            {/* <th scope="col" className="text-center">Station 4</th>
            <th scope="col" className="text-center">Station 5</th> */}
            <th scope="col" className="text-center">
              Total Result
            </th>
          </tr>
        </thead>
        <tbody>
          {contestants.map((contestant) => {
            const evaluationData = evaluations[contestant.ID] || {};
            const station1Result = calculateStationResult(
              evaluationData,
              "Station1"
            );
            const station2Result = calculateStationResult(
              evaluationData,
              "Station2"
            );
        
            const totalResult = [
              station1Result,
              station2Result,
              
            ].reduce((sum, result) => sum + result, 0);

            return (
              <tr key={contestant.ID}>
                <td className="text-center">
                  <button
                    onClick={() => handleButtonClick(contestant.ID)}
                    className="btn btn-link text-decoration-none text-primary"
                  >
                    {contestant.ID}
                  </button>
                </td>
                <td>{contestant.Name}</td>
                <td className="text-center">{station1Result || "-"}</td>
                <td className="text-center">{station2Result || "-"}</td>
               
                <td className="text-center fw-bold">{totalResult || "-"}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Score;
