import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import api from "./../api";

const Ranking = () => {
  const [contestants, setContestants] = useState([]);
  const [evaluations, setEvaluations] = useState({});
  const navigate = useNavigate();

  const getConts = async () => {
    try {
      const response = await api.post("/getAllDataConts");
      setContestants(response.data.data);
    } catch (error) {
      console.error(
        "get All Data error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const getEvaluation = async (ConstID) => {
    try {
      const response = await api.post(`/getEvaluationConts/${ConstID}`);
      setEvaluations((prevEvaluations) => ({
        ...prevEvaluations,
        [ConstID]: response.data.data,
      }));
    } catch (error) {
      console.error(
        "Error fetching evaluation:",
        error.response ? error.response.data : error.message
      );
    }
  };

  useEffect(() => {
    getConts();
  }, []);

  useEffect(() => {
    if (contestants.length > 0) {
      contestants.forEach((contestant) => {
        getEvaluation(contestant.ID);
      });
    }
  }, [contestants]);

  const calculateStationResult = (evaluationData, station) => {
    if (Array.isArray(evaluationData)) {
      const stationData = evaluationData.find((item) => item[station]);
      if (!stationData) {
        return 0;
      }
      const scores = Object.values(stationData[station]).map(
        (contestantData) => contestantData.score?.result || 0
      );
      return scores.reduce((total, score) => total + score, 0);
    }
    return 0;
  };

  const handleButtonClick = (ConstID) => {
    navigate("/detail", { state: { ConstID } });
  };

  const handleSubmit = async () => {
    const top30Contestants = rankedContestants.slice(0, 30);
    const top30Data = top30Contestants.reduce((acc, contestant, index) => {
      acc[index + 1] = {
        ID: contestant.ID,
        Name: contestant.Name,
        Result: contestant.totalResult,
      };
      return acc;
    }, {});
  
    console.log("Top 30 Contestants Data:", top30Data);
  
    try {
      const response = await setFinalranking(top30Data);
      if (response.status === 200) {
        alert("Successfully set the final ranking!");
      } else {
        alert("Failed to set the final ranking. Please try again.");
      }
    } catch (error) {
      console.error("Error while setting final ranking:", error.message);
      alert("An error occurred while setting final ranking.");
    }
  };
  
  const setFinalranking = async (Data) => {
    try {
      const response = await api.post(`/setFinalranking`, { Data });
      return response;
    } catch (error) {
      console.error("API error:", error.message);
      throw error;
    }
  };

  const contestantsWithTotal = contestants.map((contestant) => {
    const evaluationData = evaluations[contestant.ID] || {};
    const station1Result = calculateStationResult(evaluationData, "Station1");
    const station2Result = calculateStationResult(evaluationData, "Station2");
    const totalResult = station1Result + station2Result;
    return {
      ...contestant,
      station1Result,
      station2Result,
      totalResult,
    };
  });

  const rankedContestants = contestantsWithTotal.sort(
    (a, b) => b.totalResult - a.totalResult
  );

  return (
    <div className="container mt-5 position-relative">
      <h2 className="text-center text-primary mb-4">Contestant Rankings</h2>
      <table className="table table-hover table-striped shadow-sm rounded">
        <thead className="table-dark">
          <tr>
            <th className="text-center">Rank</th>
            <th className="text-center">ID</th>
            <th>Name</th>
            <th className="text-center">Station 1</th>
            <th className="text-center">Station 2</th>

            <th className="text-center">Total Result</th>
          </tr>
        </thead>
        <tbody>
          {rankedContestants.map((contestant, index) => (
            <tr key={contestant.ID}>
              <td className="text-center">{index + 1}</td>
              <td className="text-center">
                <button
                  onClick={() => handleButtonClick(contestant.ID)}
                  className="btn btn-link text-primary"
                >
                  {contestant.ID}
                </button>
              </td>
              <td>{contestant.Name}</td>
              <td className="text-center">
                {contestant.station1Result || "-"}
              </td>
              <td className="text-center">
                {contestant.station2Result || "-"}
              </td>
              <td className="text-center fw-bold">
                {contestant.totalResult || "-"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* ปุ่ม Submit */}
      <div className="d-flex justify-content-end fixed-bottom mb-3 me-5 ">
        <button className="btn btn-primary" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default Ranking;
