import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./css/Home.css";
import instructionVideo from "./image/Instruction.mp4"; // นำเข้าวิดีโอ

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email; // รับ email จาก Login
  const username = location.state?.username; // รับ username จาก Home
  const DirectorID = location.state?.DirectorID;

  const handleButtonClick = () => {
    navigate("/list-conts", { state: { email, username, DirectorID } });
  };

  return (
    <div className="container p-5">
      <div className="video-container mb-4 mt-2">
        {/* วิดีโอที่เล่นซ้ำ */}
        <video
          src={instructionVideo}
          className="img-fluid"
          autoPlay
          loop
          muted
          controls={false}
          style={{ maxWidth: "750px", maxHeight: "750px" }}
        />
      </div>
      <button className="info-button" onClick={handleButtonClick}>
        เข้าสู่หน้าการประเมิน
      </button>
    </div>
  );
};

export default Home;
